const Message = ({ message }) => {
  if (!message) return null;

  return (
    <div className="Message">
      <div className="Message__content">{message}</div>
    </div>
  );
};

Message.defaultProps = {
  message: null,
};

export default Message;
