import Target from "@/components/Target";
import clsx from "clsx";

const BodyButtons = ({ buttons }) => {
  return (
    <div className={clsx("container")}>
      <div
        className={clsx(
          "flex flex-col justify-center space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0"
        )}
      >
        {buttons.map((button) => {
          const { target } = button;

          return (
            <Target
              key={button.id}
              target={target}
              className={clsx("button button--rounded button--solid-dark")}
            >
              {target.text}
            </Target>
          );
        })}
      </div>
    </div>
  );
};

export default BodyButtons;
