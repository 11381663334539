import Target from "@/components/Target";
import ComingSoonRibbons from "@/images/coming-soon-ribbons.svg";
import cloudflareLoader from "@/lib/cloudflare-images";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import Image from "next/image";
import { Fragment, useRef, useState } from "react";
import { X } from "react-feather";

const FlexibleGrid = ({
  items,
  enableModals = true,
  addPlaceholder = false,
  placeholderHeading = "Coming Soon...",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // We need to chunk the array into 14 items each
  const itemGroups = items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 14);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  // Create a const to hold a ref to the setTimeout
  const timeoutRef = useRef(null);

  function closeModal() {
    setIsModalOpen(false);

    // Once the modal is closed, we need to reset the selected item after the animation is done
    setTimeout(() => {
      timeoutRef.current = setSelectedItem(null);
    }, 200);
  }

  function handleItemClick(item) {
    // If we're disabling modals, just return
    if (!enableModals) return;

    // Clear the timeout if it exists
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setSelectedItem(item);

    setIsModalOpen(true);
  }

  return (
    <>
      <div className={clsx("FlexibleGrid", "bg-zinc-800")}>
        {itemGroups.map((group, groupIndex) => {
          // Is this the last group?
          const isLastGroup = groupIndex === itemGroups.length - 1;

          return (
            <div
              key={groupIndex}
              className={clsx(
                "FlexibleGrid__group",
                "grid-flexible grid auto-rows-auto gap-[2px] border-b-2 border-black"
              )}
            >
              {group.map((item, itemIndex) => {
                const image = item.image?.[0];

                return (
                  <a
                    key={itemIndex}
                    className={clsx(
                      "FlexibleGrid__item",
                      "grid-item group relative flex cursor-pointer flex-col bg-transparent",
                      {
                        "pointer-events-none": !enableModals,
                      }
                    )}
                    onClick={() => handleItemClick(item)}
                  >
                    <div
                      className={clsx(
                        "FlexibleGrid__image",
                        "relative aspect-[5/4] bg-zinc-50"
                      )}
                    >
                      {!!image && (
                        <Image
                          src={image.url}
                          layout="fill"
                          objectFit="cover"
                          alt={image.title}
                          className={clsx(
                            "transition ease-out group-hover:scale-[1.025]"
                          )}
                          loader={cloudflareLoader}
                        />
                      )}
                    </div>
                    <div
                      className={clsx(
                        "FlexibleGrid__caption",
                        "flex-grow border-t-2 border-black bg-white px-4 py-2 font-spatial font-normal"
                      )}
                    >
                      {item.title || item.heading}
                    </div>
                  </a>
                );
              })}

              {/* If we're adding a placeholder, add it here */}
              {addPlaceholder && isLastGroup && (
                <a
                  className={clsx(
                    "FlexibleGrid__item",
                    "grid-item group relative flex cursor-pointer flex-col bg-transparent"
                  )}
                >
                  <div
                    className={clsx(
                      "FlexibleGrid__image",
                      "relative aspect-[5/4] bg-glass-texture"
                    )}
                  >
                    <Image
                      src={ComingSoonRibbons}
                      layout="fill"
                      objectFit="cover"
                      alt={"Coming Soon"}
                      loader={cloudflareLoader}
                    />
                  </div>
                  <div
                    className={clsx(
                      "FlexibleGrid__caption",
                      "flex-grow border-t-2 border-black bg-white px-4 py-2 font-spatial font-normal"
                    )}
                  >
                    {placeholderHeading}
                  </div>
                </a>
              )}
            </div>
          );
        })}
      </div>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className={clsx(
            "FlexibleGrid__dialog",
            "fixed inset-0 z-20 overflow-y-auto"
          )}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                "FlexibleGrid__dialogOverlay",
                "fixed inset-0 bg-zinc-800 bg-opacity-75"
              )}
            />
          </Transition.Child>

          <div className={clsx("fixed inset-0 overflow-y-auto")}>
            <div
              className={clsx(
                "flex min-h-full items-center justify-center px-6 pt-12 lg:pt-0"
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    "FlexibleGrid__dialogPanel",
                    "isolate isolate w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white transition-all"
                  )}
                >
                  <button
                    className={clsx(
                      "absolute right-2 top-2 z-10 flex h-9 w-9 items-center justify-center rounded-full bg-zinc-800/50 text-white opacity-75 hover:opacity-100"
                    )}
                    onClick={closeModal}
                  >
                    <X size={21} strokeWidth={2} />
                  </button>
                  <div className={clsx("flex flex-col lg:flex-row")}>
                    <div className={clsx("w-full lg:w-1/2")}>
                      <div
                        className={clsx(
                          "relative aspect-4/3 lg:aspect-auto lg:h-full lg:w-full"
                        )}
                      >
                        {!!selectedItem?.image && (
                          <Image
                            src={selectedItem?.image?.[0]?.url}
                            layout="fill"
                            objectFit="cover"
                            alt={selectedItem?.image?.[0]?.title}
                            loader={cloudflareLoader}
                          />
                        )}
                      </div>
                    </div>
                    <div className={clsx("w-full lg:w-1/2")}>
                      <div
                        className={clsx("bg-zinc-800 p-6 text-white lg:p-12")}
                      >
                        <div className={clsx("")}>
                          <Dialog.Title
                            as="h3"
                            className={clsx("heading-md mb-4")}
                          >
                            {selectedItem?.title}
                          </Dialog.Title>

                          <div
                            className={clsx(
                              "scrollable mb-6 h-40 pr-6 lg:h-auto lg:overflow-y-visible lg:pr-0"
                            )}
                          >
                            <div
                              className={clsx("body-text")}
                              dangerouslySetInnerHTML={{
                                __html: selectedItem?.textContent,
                              }}
                            ></div>
                          </div>

                          {!!selectedItem?.target &&
                            !!selectedItem.target?.url && (
                              <div className="">
                                <Target
                                  target={selectedItem?.target}
                                  className={clsx(
                                    "button button--solid-light button--rounded"
                                  )}
                                  openInNewWindow
                                >
                                  {selectedItem?.target.text}
                                </Target>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FlexibleGrid;
