import clsx from "clsx";

const ArticleHeading = ({
  children,
  element = "h2",
  size = "lg",
  alignment = "left",
}) => {
  const HeadingElement = element;

  return (
    <HeadingElement
      className={clsx("ArticleHeading", "px-6 lg:px-12", {
        "heading-xs--thin": size === "xs",
        "heading-sm--thin": size === "sm",
        "heading-md--thin": size === "md",
        "heading-lg--thin": size === "lg",
        "heading-xl--thin": size === "xl",
        "heading-2xl--thin": size === "2xl",
        "text-center": alignment === "center",
      })}
    >
      {children}
    </HeadingElement>
  );
};

export default ArticleHeading;
