import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/image";

const ArticleImages = ({ images, aspectRatio, layout = "sideBySide" }) => {
  return (
    <div
      className={clsx(
        "flex flex-col divide-y-2 divide-zinc-950 md:flex-row md:divide-x-2 md:divide-y-0"
      )}
    >
      {images.map((image, index) => {
        return (
          <div
            key={image.id}
            className={clsx("", {
              "w-full md:w-1/2": layout === "sideBySide",
              "w-full": layout === "fullWidth",
            })}
          >
            <div
              className={clsx("relative", {
                "aspect-4/3": layout === "sideBySide",
                "aspect-4/3 md:aspect-16/9 2xl:aspect-2.75/1":
                  layout === "fullWidth",
              })}
            >
              <Image
                src={image.url}
                layout="fill"
                objectFit="cover"
                alt={image.title}
                loader={cloudflareLoader}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ArticleImages;
