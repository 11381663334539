import Button from "components/Button";
import Loading from "components/Loading";
import Message from "components/Message";
import dayJs from "dayjs";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const CompetitionEntry = observer(({ entry }) => {
  const { authStore, competitionStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [hasEntered, setHasEntered] = useState(null);
  const [error, setError] = useState(null);
  const router = useRouter();

  const { currentUser } = authStore;

  const isOpen = dayJs().isAfter(dayJs(entry.competitionStartTime));
  const isEnded = dayJs().isAfter(dayJs(entry.competitionEndTime));
  const canEnter = isOpen && !isEnded;

  // On mount check if the user has entered the competition
  useEffect(() => {
    setIsLoading(true);

    const request = competitionStore.checkEntry(entry.id);

    request.then((response) => {
      setHasEntered(response.data);
      setIsLoading(false);
    });
  }, [competitionStore, entry.id, currentUser]);

  const handleClick = () => {
    setIsLoading(true);

    const request = competitionStore.createEntry(entry.id);

    request.then((response) => {
      if (response.data.error) setError(response.data.error);
      if (response.data.status) setHasEntered(response.data.status);

      setIsLoading(false);
    });
  };

  const handleLogin = () => {
    router.push("/signin");
  };

  return (
    <div className="CompetitionEntry">
      {error && <div className="CompetitionEntry__error">{error}</div>}
      {currentUser && (
        <div className="CompetitionEntry__content">
          {isLoading && (
            <div className="CompetitionEntry__loading">
              <Loading color="#000" />
            </div>
          )}
          {!isLoading && !hasEntered && canEnter && (
            <div className="ComeptitionEntry__button">
              <Button label="Enter" theme="light" onClick={handleClick} />
            </div>
          )}
          {!isLoading && hasEntered && (
            <div className="ComeptitionEntry__message">
              <Message message="Thank you for entering!" />
            </div>
          )}
          {!isLoading && !isOpen && !isEnded && (
            <div className="ComeptitionEntry__message">
              <Message
                message={`Entries are open on ${dayJs(
                  entry.competitionStartTime
                ).format("MMMM D, YYYY, h:mma")}`}
              />
            </div>
          )}
          {!isLoading && !isOpen && isEnded && (
            <div className="ComeptitionEntry__message">
              <Message
                message={`Entries closed on ${dayJs(
                  entry.competitionEndedTime
                ).format("MMMM D, YYYY, h:mma")}`}
              />
            </div>
          )}
        </div>
      )}
      {!currentUser && (
        <div className="CompetitionEntry__content">
          <div className="ComeptitionEntry__button">
            <Button
              label="Start Free Trial or Login"
              theme="light"
              onClick={handleLogin}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default CompetitionEntry;
